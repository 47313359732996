import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Link } from '../src/ui_kit';

function CartIcon() {
  const [lineItemsQuantity, setLineItemsQuantity] = useState(0);

  function fetchLineItemsQuantity() {
    $.getJSON(`/cart`, (result) => {
      setLineItemsQuantity(result.line_items_quantity);
    });
  }

  useEffect(() => {
    fetchLineItemsQuantity();

    window.EventSystem.subscribe('cart-icon.reload', function () {
      fetchLineItemsQuantity();
    });
  }, []);

  return (
    <Link id="component-cart-icon" className="link-with-icon" href="/cart">
      <div
        className={`
          quantity-circle 
          ${lineItemsQuantity < 1 ? 'no-items' : ''} 
          ${lineItemsQuantity >= 100 ? 'triple-digit' : ''}
        `}
      >
        {lineItemsQuantity}
      </div>
      <span className="icon iconify left-icon " data-icon="fa-solid:shopping-cart" data-inline="false" />
      <div className={`icon-label ${lineItemsQuantity < 1 ? 'no-items' : 'items'}`}>Cart</div>
    </Link>
  );
}

document.addEventListener('DOMContentLoaded', () => {
  const element = document.getElementById('cart-icon-mount-point');

  if (element) {
    ReactDOM.render(<CartIcon />, element);
  }
});
